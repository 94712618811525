<template>
    <div :class="{'border border-2 border-gray-400 rounded-lg': !onlyContent}">
        <div v-if="!onlyContent" class="text-xs py-1 bg-blue-600 w-full rounded-tl-lg rounded-tr-lg">
            <span class="ml-2 text-white-text">{{ $t('pages.tenant_client.note')}}</span>
        </div>
        <div class="py-2 px-2 text-sm">{{ $t('pages.block_admin.gap_validation_attribute_set_to_true') }}</div>
        <div class="py-2 px-2">
            <div class="py-2 flex justify-between items-center">
                <label for="" class="font-semibold">{{ $t('pages.block_admin.valid_period_(days)') }}</label>
                <input min="0" class="border border-gray-400 rounded-lg px-2 w-40" @input="handleChange" v-model.number="valid_period" type="number" />
            </div>
            <div class="py-2 flex justify-between items-center">
                <label for="" class="font-semibold">{{ $t('pages.block_admin.history_(months)') }}</label>
                <input min="0" class="border border-gray-400 rounded-lg px-2 w-40" @input="handleChange" v-model.number="history" type="number" />
            </div>
        </div>
        <div class="flex justify-end p-2" v-if="showSave">
            <button class="text-sm font-semibold normal-case px-6 leading-3 h-auto min-h-0 py-2.5 gap-2 flex items-center no-animation focus:bg-blue-700 rounded-xl btn-primary mt-4" @click="onGapValidationPayload()">{{ $t('pages.field_table.save') }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "checkGapValidationInput",
    data() {
        return {
            valid_period: 30,
            history: 12,
        };
    },
    props: {
        valid_period_prop: {
            type: Number,
            default: 30,
        },
        history_prop: {
            type: Number,
            default: 12,
        },
        showSave: {
            type: Boolean,
            default: false,
        },
        onlyContent: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.valid_period = this.valid_period_prop ?? 30;
        this.history = this.history_prop ?? 12;
    },
    methods: {
        fetchPayload() {
            return {
                valid_gap: this.valid_period,
                history: this.history,
            };
        },
        handleChange() {
            this.$emit("handleGapValidationPayload", this.fetchPayload());
        },
        onGapValidationPayload() {
            this.$emit("onGapValidationPayload", this.fetchPayload());
        },
    },
};
</script>

<style scoped></style>
